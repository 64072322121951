// This file was automatically generated from concierge.availability.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }


Nightloop.Templates.Concierge.Availability = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="conciergeavailview"><div class="availselect guests"><select class=\'guests\' name="guests" onChange="this.blur()"><option value="">select</option>');
  var choiceList10475 = opt_data.guests_options;
  var choiceListLen10475 = choiceList10475.length;
  for (var choiceIndex10475 = 0; choiceIndex10475 < choiceListLen10475; choiceIndex10475++) {
    var choiceData10475 = choiceList10475[choiceIndex10475];
    output.append('<option value="', soy.$$escapeHtml(choiceData10475[0]), '"', (choiceData10475[0] == opt_data.guests) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData10475[1]), '</option>');
  }
  output.append('</select><div class="title">Party size</div></div><div class="availselect date"><div class="val"></div><div class="title">Date</div><input class=\'avail-date\' type="hidden" name="date" value="', soy.$$escapeHtml(opt_data.date), '" ></div><div class="avail-date-calendar-container no-display"><div  class=\'avail-date-calendar\'></div></div><div class="availselect time"><select class=\'time_slot\' name="time_slot" onChange="this.blur()"><option value="">select</option>');
  var choiceList10489 = opt_data.time_options;
  var choiceListLen10489 = choiceList10489.length;
  for (var choiceIndex10489 = 0; choiceIndex10489 < choiceListLen10489; choiceIndex10489++) {
    var choiceData10489 = choiceList10489[choiceIndex10489];
    output.append('<option value="', soy.$$escapeHtml(choiceData10489), '" ', (choiceData10489 == opt_data.time_slot) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData10489), '</option>');
  }
  output.append('</select><div class="title">Time</div></div><div class="availbutton">search</div><div class="float-end"></div><div class="results no-display"><div class="heading">AVAILABILITY ON <span class="searched-date"></span>&nbsp; AROUND <span class="searched-timeslot">', soy.$$escapeHtml(opt_data.time_slot), '</span>&nbsp; FOR <span class="searched-guests">', soy.$$escapeHtml(opt_data.guests), '</span> PEOPLE</div><div class="rows"><div class="slot s-0"></div><div class="slot s-1"></div><div class="slot s-2"></div><div class="slot s-3"></div><div class="slot s-4"></div><div class="slot s-5"></div><div class="slot s-6"></div></div><div><div class="no-results no-display float-left">NO AVAILABILITY FOUND</div>', (opt_data.can_custom_dining_request) ? '<div class="submit-request"><div class="desc">Can\'t find what you\'re looking for?</div><div class="req"><a href="javascript:void(0);" class="custom-reservation-popup-link custom-request-btn" venue_name="' + soy.$$escapeHtml(opt_data.venue_name) + '" venue_id="' + soy.$$escapeHtml(opt_data.venue_id) + '" >request</a></div><div class="float-end"></div></div>' : '', '<div class="float-end"></div></div><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.AvailabilityRow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="row"><div class="date ', (opt_data.is_search) ? 'searched' : '', '"><div>', soy.$$escapeHtml(opt_data.date_formatted), '</div></div><div class="times">');
  if (opt_data.is_empty_code == 'VENUE_CLOSED') {
    output.append('<div class="empty_code">', soy.$$escapeHtml(opt_data.venue_name), ' is closed on this date</div>');
  } else if (opt_data.is_empty_code == 'NO_RESULTS') {
    output.append('<div class="empty_code">No availability found</div>');
  } else {
    var timeslotList10529 = opt_data.time_slots;
    var timeslotListLen10529 = timeslotList10529.length;
    for (var timeslotIndex10529 = 0; timeslotIndex10529 < timeslotListLen10529; timeslotIndex10529++) {
      var timeslotData10529 = timeslotList10529[timeslotIndex10529];
      Nightloop.Templates.Concierge.AvailabilityOption({base_url: opt_data.base_url, venue_class: opt_data.venue_class, venue_id: opt_data.venue_id, max_guests: opt_data.max_guests, reservation_id: opt_data.reservation_id, date_url: opt_data.date_url, timeslot: timeslotData10529, previous_reservation_id: opt_data.previous_reservation_id, isLast: timeslotIndex10529 == timeslotListLen10529 - 1}, output);
    }
  }
  output.append('<div class="float-end"></div></div><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.UpcomingAvailability = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="upcoming-avail">');
  var party_sizeList10544 = soy.$$getMapKeys(opt_data.avail_by_party_size);
  var party_sizeListLen10544 = party_sizeList10544.length;
  for (var party_sizeIndex10544 = 0; party_sizeIndex10544 < party_sizeListLen10544; party_sizeIndex10544++) {
    var party_sizeData10544 = party_sizeList10544[party_sizeIndex10544];
    output.append('<div class="title">Next available tables for ', soy.$$escapeHtml(party_sizeData10544), '</div><div class="results">');
    var availList10548 = opt_data.avail_by_party_size[party_sizeData10544];
    var availListLen10548 = availList10548.length;
    for (var availIndex10548 = 0; availIndex10548 < availListLen10548; availIndex10548++) {
      var availData10548 = availList10548[availIndex10548];
      var timeslotList10549 = availData10548.search_result.timeslots;
      var timeslotListLen10549 = timeslotList10549.length;
      for (var timeslotIndex10549 = 0; timeslotIndex10549 < timeslotListLen10549; timeslotIndex10549++) {
        var timeslotData10549 = timeslotList10549[timeslotIndex10549];
        Nightloop.Templates.Concierge.AvailabilityOption({base_url: opt_data.base_url, venue_class: opt_data.venue_class, venue_id: opt_data.venue_id, max_guests: availData10548.max_guests, reservation_id: availData10548.reservation_id, date_url: availData10548.date_url, timeslot: timeslotData10549, dateFormatted: availData10548.dateFormatted, isLast: timeslotIndex10549 == timeslotListLen10549 - 1}, output);
      }
    }
    output.append((opt_data.can_custom_dining_request) ? '<div class="req"><a href="javascript:void(0);" class="custom-reservation-popup-link custom-request-btn" venue_name="' + soy.$$escapeHtml(opt_data.venue_name) + '" venue_id="' + soy.$$escapeHtml(opt_data.venue_id) + '" >request</a></div>' : (! opt_data.has_avail_by_party_size[party_sizeData10544]) ? 'No availability' : '', '<div class="float-end"></div></div>');
  }
  output.append('</div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.AvailabilityOption = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="booking-option ', (! opt_data.timeslot.time_slot) ? 'empty' : '', ' ', (opt_data.isLast) ? 'last' : '', ' ', (opt_data.dateFormatted) ? 'has-date' : '', '">', (opt_data.timeslot.time_slot) ? '<form class="ajaxify" method="GET" action="' + soy.$$escapeHtml(opt_data.base_url) + '/res/enter-client-info/' + soy.$$escapeHtml(opt_data.reservation_id) + '"><input type="hidden" name="venue_class" value="' + soy.$$escapeHtml(opt_data.venue_class) + '" /><input type="hidden" name="venue_id" value="' + soy.$$escapeHtml(opt_data.venue_id) + '" /><input type="hidden" name="time_slot" value="' + soy.$$escapeHtml(opt_data.timeslot.time_slot) + '" /><input type="hidden" name="date" value="' + soy.$$escapeHtml(opt_data.date_url) + '" /><input type="hidden" name="max_guests" value="' + soy.$$escapeHtml(opt_data.max_guests) + '" /><input type="hidden" name="access_persistent_id" value="' + soy.$$escapeHtml(opt_data.timeslot.access_persistent_id) + '" />' + ((opt_data.previous_reservation_id) ? '<input type="hidden" name="previous_reservation_id" value="' + soy.$$escapeHtml(opt_data.previous_reservation_id) + '" />' : '') + '<a class="booking-option-link" href="javascript:void(0);" onclick="$(this).closest(\'form\').submit();">' + ((opt_data.dateFormatted) ? '<span class="book-date">' + soy.$$escapeHtml(opt_data.dateFormatted) + '</span>' : '') + '<span class="book-time">' + soy.$$escapeHtml(opt_data.timeslot.time_display) + '</span></a></form>' : '&nbsp;', '</div>');
  return opt_sb ? '' : output.toString();
};
