// This file was automatically generated from concierge.manage.conciergepromoters.view.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Manage == 'undefined') { Nightloop.Templates.Concierge.Manage = {}; }


Nightloop.Templates.Concierge.Manage.ConciergePromotersView = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Concierge.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Concierge.Manage.ConciergePromotersViewBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Manage.ConciergePromotersViewBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-conciergepromoters"><div><div class="content-header float-left"><span class="header-text">Booked By Names</span>  <a id="contextual-link-bookedby" class="contextual-link" href="javascript:void(0);">(?)</a><div id="contextual-content-bookedby" class="contextual-content">These names appear in the \'Booked by\' drop-down when concierge staff book new reservations.</div></div><div class="float-end"></div></div><div id="main-area" class="float-left w50">', (opt_data.content.message) ? '<div class="white-box gold-message">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', '<div class="body-block white-box"><div class="white-box-block"><div class="block-header"><div class="header-text float-left">Names</div><div id="edit-button" class="float-right"><a id="edit-bookedby-link" class="float-left ajaxify" href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/manage/bookedbynames/edit">');
  Nightloop.Templates.Widget.GoldButton({text: 'EDIT', size: 'medium'}, output);
  output.append('</a></div><div class="float-end"></div></div></div><div class="white-box-block">');
  var concierge_promoterList12183 = opt_data.content.concierge_promoters;
  var concierge_promoterListLen12183 = concierge_promoterList12183.length;
  for (var concierge_promoterIndex12183 = 0; concierge_promoterIndex12183 < concierge_promoterListLen12183; concierge_promoterIndex12183++) {
    var concierge_promoterData12183 = concierge_promoterList12183[concierge_promoterIndex12183];
    output.append('<div class="concierge-promoter-name">', soy.$$escapeHtml(concierge_promoterData12183.name), '</div>');
  }
  output.append('</div></div></div><div class="float-end"></div></div><script type="text/javascript">$(document).ready( function() {Pmp.Concierge.Manage.ConciergePromotersView.initialize();})<\/script>');
  return opt_sb ? '' : output.toString();
};
